<template>
<div class="modal-dialog">
    <div class="modal-content">
        <form @submit.prevent="login">
            <a class="navbar-brand" href="/"><img alt="Logo" src="../assets/logo.png"></a>
            <div class="modal-body text-start">
                <div class="form-group">
                    <input type="text" autocorrect="off" autocapitalize="none" autocomplete="off" class="form-control" v-model="userCredentials.username" placeholder="Korisničko ime">
                    <div class="invalid-feedback">Invalid username.</div>
                </div>
                <div class="form-group">
                    <input type="password" class="form-control" autocomplete="on" v-model="userCredentials.password" placeholder="Lozinka">
                    <div class="invalid-feedback">Invalid password.</div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="submit" class="btn btn-primary">Prijavi se</button>
            </div>
        </form>
    </div>
</div>
</template>

<script>
import AuthenticationService from '@/service/AuthenticationService.js';
import {mapState} from 'vuex'


export default {
    name: "Login",
    data() {
        return {
            userCredentials: 
            {
                username: "",
                password: ""
            }
        }
    },
    computed: {
        ...mapState([
            'user',
        ]),
    },

    methods: {
        async login() {
            if (this.userCredentials.username == "" || this.userCredentials.password == "")
                return;

            let login = await AuthenticationService.login(this.userCredentials);
            if (!login) {                
                this.toast('Uneli ste pogrešno korisničko ime ili lozinku', 'error');
                return;
            }

            this.toast('Dobrodošao ' + this.userCredentials.username, 'success');
            this.$router.push('/');
        },

        toast(message, type) {
            this.$toasted.show(message, { 
                type: type,
                theme: "toasted-primary", 
                position: "top-center", 
                duration : 3000
            });
        }
    }

}
</script>

<style scoped>

.navbar-brand img {
  height: 60px;
  float: inside;
  margin-right: 30px;
  margin-left: 30px;
}

input {
  outline: none;
  border-radius: 25px;
  border: none;
  background: #d3d3d3;
  font-family: Tahoma;
  margin-bottom: 15px;
}

label {
  font-family: Tahoma;
}

.btn {
  margin: auto;
  display: block;
}

a {
  color: blue;
  text-decoration: underline;
}
</style>